var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('GROUP_WRITE') ? _c('v-btn', _vm._b({
    attrs: {
      "to": {
        name: 'NewGroup'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Csoportok")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    attrs: {
      "label": "Megnevezés",
      "clearable": "",
      "hide-details": "",
      "dense": "",
      "mask": _vm.$config.inputMasks.name
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Típus",
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      "dense": "",
      "items": _vm.groupTypes
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "type_id", $$v);
      },
      expression: "search.type_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.actions`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm.hasRight('GROUP_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm m-2",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "primary",
            "to": {
              name: 'GroupEditor',
              params: {
                id: item.element_id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('GROUP_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm m-2",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "primary",
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteGroup(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }